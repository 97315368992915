import React from "react"
import Layout from "../components/layout"
import {graphql} from "gatsby";
import Img from "gatsby-image";

export default (props) => {
   return (
      <Layout>
         <div className='single-photo-container'>
            <Img
               fluid={props.data.allMarkdownRemark.edges[0].node.fields.image.childImageSharp.fluid}
               className='single-photo'
               imgStyle={{objectFit: 'contain'}}
            />
         </div>
      </Layout>
   )
};

export const contactPageQuery = graphql`
    query homePagePhoto {
        allMarkdownRemark(filter: {frontmatter: { title: { eq: "IMG_3187-1" } } } ) {
           edges {
              node {
                 fileAbsolutePath
                 frontmatter {
                     title
                     file
                   }
                   fields {
                        image {
                         childImageSharp {
                           fluid(maxWidth: 3500, quality: 100) {
                           # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                              ...GatsbyImageSharpFluid
                           }
                         }
                       }
                   }
                
              }
           }
        }
    }
`